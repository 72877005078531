// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.pageOne, icon: ICONS.dashboard }]
  },

  {
    subheader: 'pages',
    items: [
      {
        title: 'Content Pages',
        path: PATH_DASHBOARD.pages.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Pages', path: PATH_DASHBOARD.pages.all },
          { title: 'Add New Page', path: PATH_DASHBOARD.pages.add }
        ]
      }
    ]
  },

  {
    subheader: 'articles',
    items: [
      {
        title: 'Blogs',
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.analytics,
        children: [
          { title: 'All Blogs', path: PATH_DASHBOARD.blogs.all },
          { title: 'Add New Blog', path: PATH_DASHBOARD.blogs.add }
        ]
      }
    ]
  },
  {
    subheader: ' article Tags',
    items: [{ title: 'Manage Tags', path: PATH_DASHBOARD.tags.all, icon: ICONS.banking }]
  },
  {
    subheader: 'article Category',
    items: [{ title: 'Manage Category', path: PATH_DASHBOARD.category.all, icon: ICONS.user }]
  },
  {
    subheader: 'ManageForums',
    items: [{ title: 'Forums', path: PATH_DASHBOARD.forums.all, icon: ICONS.user }]
  },
  {
    subheader: ' Writer',
    items: [{ title: 'Manage Writers', path: PATH_DASHBOARD.writers.all, icon: ICONS.banking }]
  }
];

export default sidebarConfig;
