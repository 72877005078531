import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizationToken } from '../utils/setAuthorizationToken';
import { userAPI } from '../api/userAPI';
import { setMessage, setIsAuthenticated } from './appSlice';

// async thunk request to User Request
export const getUserRequest = createAsyncThunk(
  'user/getUserRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.getCpUser();
      dispatch(setUserDetail(data));
    } catch (error) {
      if (error.response) {
        localStorage.removeItem('fxAdminToken');
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Login
export const loginRequest = // eslint-disable-next-line
  createAsyncThunk('user/loginRequest', async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const getToken = await userAPI.loginRequest(userdata);
      localStorage.setItem('fxAdminToken', JSON.stringify(getToken.accessToken));
      localStorage.setItem('fxAdminRefToken', JSON.stringify(getToken.refreshToken));
      setAuthorizationToken(getToken.accessToken);
      dispatch(setIsAuthenticated(true));
      dispatch(getUserRequest());
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  });

// async thunk request to get refresh token
export const refreshTokenRequest = // eslint-disable-next-line
  createAsyncThunk('user/refreshTokenRequest', async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const getToken = await userAPI.refreshToken(userdata);
      localStorage.setItem('fxAdminToken', JSON.stringify(getToken.accessToken));
      localStorage.setItem('fxAdminRefToken', JSON.stringify(getToken.refreshToken));
      setAuthorizationToken(getToken.accessToken);
      dispatch(setIsAuthenticated(true));
      dispatch(getUserRequest());
    } catch (error) {
      if (error.response) {
        dispatch(setIsAuthenticated(false));
        localStorage.removeItem('fxAdminToken');
        localStorage.removeItem('fxAdminRefToken');
        return rejectWithValue(error.response.data.message);
      }
    }
  });

// async thunk request to User Log OUT
// eslint-disable-next-line
export const userLogoutRequest = createAsyncThunk(
  'user/userLogoutRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.logoutCall();
      dispatch(setMessage(data.message));
      localStorage.removeItem('fxAdminToken');
      localStorage.removeItem('fxAdminRefToken');
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserDetail({}));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get Business Config
// eslint-disable-next-line
export const getBusinessConfigRequest = createAsyncThunk(
  'user/getBusinessConfigRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.getConfig();
      dispatch(setConfig(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to update Business Config
// eslint-disable-next-line
export const updateBusinessConfigRequest = createAsyncThunk(
  'user/updateBusinessConfigRequest',
  // eslint-disable-next-line
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.updateConfig(userData);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User Log OUT
export const changePasswordRequest = createAsyncThunk(
  'user/changePasswordRequest',
  // eslint-disable-next-line
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.changePassword(userData);
      dispatch(setServerMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to update user profile pic
export const changeProfilePicRequest = createAsyncThunk(
  'user/changeProfilePicRequest',
  // eslint-disable-next-line
  async (image, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.changePic(image);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  userDetails: {},
  config: {},
  hasError: '',
  serverMessage: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetails = action.payload;
    },
    setServerMessage: (state, action) => {
      state.serverMessage = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    clearHasError: (state) => {
      state.hasError = '';
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getUserRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(userLogoutRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(changePasswordRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(getBusinessConfigRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
  }
});
export const { setUserDetail, clearHasError, setConfig, setServerMessage } = userSlice.actions;

export default userSlice.reducer;
