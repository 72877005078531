import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notificationAPI } from '../api/notificationAPI';
import { appLoading } from './appSlice';

// async thunk request to get All Notifications
export const getMyNotificationsRequest = createAsyncThunk(
  'notifications/getMyNotificationsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await notificationAPI.myNotifications();
      dispatch(setAllNotifications(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to read All Notifications at once
export const bulkReadNotificationRequest = createAsyncThunk(
  'notifications/bulkReadNotificationRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      await notificationAPI.readAllNotifications();
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to read notification
export const readNotificationRequest = createAsyncThunk(
  'notifications/readNotificationRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      await notificationAPI.readNotification(dt);
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  myNotifications: [],
  errorInNotification: ''
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setAllNotifications: (state, action) => {
      state.myNotifications = action.payload;
    },
    clearHasError: (state) => {
      state.errorInNotification = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNotificationsRequest.rejected, (state, action) => {
      state.errorInNotification = action.payload;
    });
    builder.addCase(readNotificationRequest.rejected, (state, action) => {
      state.errorInNotification = action.payload;
    });
    builder.addCase(bulkReadNotificationRequest.rejected, (state, action) => {
      state.errorInNotification = action.payload;
    });
  }
});
export const { setAllNotifications, clearHasError } = notificationSlice.actions;

export default notificationSlice.reducer;
