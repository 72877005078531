import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_URL}/admin`;

export const writerAPI = {
  add: async (info) => axios.post(`${baseURL}/this-route-is-not-working-at-this-moment`, info).then((res) => res.data),
  all: async () => axios.get(`${baseURL}/all-writers`).then((res) => res.data),
  single: async (idToSearch) => axios.get(`${baseURL}/get-writer/${idToSearch}`).then((res) => res.data),
  update: async (idToSearch) => {
    const { id, ...details } = idToSearch;
    return axios.put(`${baseURL}/update-existing-writer-cred/${id}`, details).then((res) => res.data);
  },
  delete: async (idToSearch) =>
    axios.delete(`${baseURL}/completely-delete-writer/${idToSearch}`).then((res) => res.data)
};
