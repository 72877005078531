import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { categoryAPI } from '../api/categoryAPI';
import { appLoading, setMessage } from './appSlice';

// async thunk request to Register new category
export const addCategoryRequest = // eslint-disable-next-line
  createAsyncThunk('category/addCategoryRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const reg = await categoryAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All category
export const getAllCategoryRequest = createAsyncThunk(
  'category/getAllCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await categoryAPI.all();
      dispatch(setAllCategory(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single request
export const getSingleCategoryRequest = createAsyncThunk(
  'category/getSingleCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await categoryAPI.single(dt);
      dispatch(setSingleCategory(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateCategoryRequest
export const updateCategoryRequest = createAsyncThunk(
  'category/updateCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await categoryAPI.update(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteCategoryRequest
export const deleteCategoryRequest = createAsyncThunk(
  'category/deleteCategoryRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await categoryAPI.delete(dt);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allCategory: [],
  singleCategory: {},
  errorInCategory: ''
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setAllCategory: (state, action) => {
      state.allCategory = action.payload;
    },
    setSingleCategory: (state, action) => {
      state.singleCategory = action.payload;
    },
    clearHasError: (state) => {
      state.errorInCategory = '';
    }
  },

  extraReducers: (builder) => {
    builder.addCase(addCategoryRequest.rejected, (state, action) => {
      state.errorInCategory = action.payload;
    });
    builder.addCase(getAllCategoryRequest.rejected, (state, action) => {
      state.errorInCategory = action.payload;
    });
    builder.addCase(getSingleCategoryRequest.rejected, (state, action) => {
      state.errorInCategory = action.payload;
    });
    builder.addCase(updateCategoryRequest.rejected, (state, action) => {
      state.errorInCategory = action.payload;
    });
    builder.addCase(deleteCategoryRequest.rejected, (state, action) => {
      state.errorInCategory = action.payload;
    });
  }
});
export const { setAllCategory, setSingleCategory, clearHasError } = categorySlice.actions;

export default categorySlice.reducer;
