import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 100 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100" height="96" fill="white" />
        <path
          d="M21.1938 59.1709V66.0281H38.7555V76.1995H21.1938V88.9995H7V48.9995H41.2214V59.1709H21.1938ZM71.7614 88.9995L64.4841 78.3138L57.3872 88.9995H41.2087L56.4249 68.8281L41.75 48.9995H57.6879L64.8449 59.0567L71.8215 48.9995H87.0979L72.4831 68.3138L88 88.9995H71.7614Z"
          fill="#F2994A"
        />
        <path
          d="M13 41.9995L29.8463 26.6395L34.6593 41.9995L57.121 18.9595L62.7364 34.3194L86 9.99951"
          stroke="#F2994A"
          strokeWidth="3"
        />
        <path d="M81.6175 5.46152L91.4301 2.99978L87.7669 15.0177L81.6175 5.46152Z" fill="#F2994A" />
      </svg>
    </Box>
  );
}
