import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/main" replace /> },
        {
          path: 'main',
          element: <DashboardPage />
        },
        { path: 'logout', element: <PageFive /> },
        { path: 'change-profile-pic', element: <ChangeProfileImage /> },
        { path: 'change-password', element: <ChangePassword /> },
        {
          path: 'blogs',
          children: [
            { path: '', element: <Navigate to="/blogs/all-blogs" replace /> },
            { path: 'all-blogs', element: <BlogsList /> },
            { path: 'add-new-blog', element: <AddNewBlog /> },
            { path: 'all-blogs/:id', element: <AddNewBlog isEdit /> },
            { path: 'all-blogs/delete/:id', element: <DeleteBlog /> }
          ]
        },

        {
          path: 'pages',
          children: [
            { path: '', element: <Navigate to="/pages/all-pages" replace /> },
            { path: 'all-pages', element: <PagesList /> },
            { path: 'add-new-page', element: <AddNewPage /> },
            { path: 'all-pages/:id', element: <AddNewPage isEdit /> },
            { path: 'all-pages/delete/:id', element: <DeletePage /> }
          ]
        },

        {
          path: 'category',
          children: [
            { path: '', element: <Navigate to="/category/all-blogs-category" replace /> },
            { path: 'all-blogs-category/delete/:id', element: <DeleteCategory /> },
            { path: 'all-blogs-category', element: <CategoryList /> },
            { path: 'all-blogs-category/:id', element: <CategoryList edit /> }
          ]
        },

        {
          path: 'forums',
          children: [
            { path: '', element: <Navigate to="/forums/all-forums" replace /> },
            { path: 'all-forums/delete/:id', element: <DeleteForum /> },
            { path: 'all-forums/:id/edit', element: <ForumsList edit /> },
            { path: 'all-forums', element: <ForumsList /> }
          ]
        },
        {
          path: 'tags',
          children: [
            { path: '', element: <Navigate to="/tags/all-tags" replace /> },
            { path: 'all-tags/delete/:id', element: <DeleteTag /> },
            { path: 'all-tags', element: <TagsList /> },
            { path: 'all-tags/:id', element: <TagsList edit /> }
          ]
        },
        {
          path: 'writers',
          children: [
            { path: '', element: <Navigate to="/writers/all-writers" replace /> },
            { path: 'all-writers/delete/:id', element: <DeleteWriter /> },
            { path: 'all-writers', element: <WritersList /> },
            { path: 'all-writers/:id', element: <WritersList edit /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <LandingPage />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));

// Static Page imports starts here
const AddNewPage = Loadable(lazy(() => import('../pages/staticPages/AddNewPage')));
const PagesList = Loadable(lazy(() => import('../pages/staticPages/AllPagesList')));
const DeletePage = Loadable(lazy(() => import('../pages/staticPages/DeletePage')));

// Blogs Page imports starts here
const AddNewBlog = Loadable(lazy(() => import('../pages/blogs/AddNewBlog')));
const BlogsList = Loadable(lazy(() => import('../pages/blogs/BlogsList')));
const DeleteBlog = Loadable(lazy(() => import('../pages/blogs/DeleteBlog')));

// Forum Page imports starts here
const ForumsList = Loadable(lazy(() => import('../pages/forums/ForumsList')));
const DeleteForum = Loadable(lazy(() => import('../pages/forums/DeleteForum')));

// Category Page imports starts here
const CategoryList = Loadable(lazy(() => import('../pages/category/CategoryList')));
const DeleteCategory = Loadable(lazy(() => import('../pages/category/DeleteCategory')));
const ChangeProfileImage = Loadable(lazy(() => import('../pages/ChangeProfileImage')));
const ChangePassword = Loadable(lazy(() => import('../pages/ChangePassword')));

// Tags Page imports starts here
const TagsList = Loadable(lazy(() => import('../pages/tags/TagsList')));
const DeleteTag = Loadable(lazy(() => import('../pages/tags/DeleteTag')));

// Writer Page imports starts here
const WritersList = Loadable(lazy(() => import('../pages/writers/WritersList')));
const DeleteWriter = Loadable(lazy(() => import('../pages/writers/DeleteWriter')));

const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
