import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { forumAPI } from '../api/forumAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new Lead
export const addNewForumRequest = // eslint-disable-next-line
  createAsyncThunk('forums/addNewForumRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await forumAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        console.log(error.response, 'error response: ');
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All forums
export const getAllForumsRequest = createAsyncThunk(
  'forums/getAllForumsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await forumAPI.all();
      dispatch(setAllForums(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single lead
export const getSingleForumRequest = createAsyncThunk(
  'forums/getSingleForumRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await forumAPI.single(dt);
      dispatch(setSingleForum(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteForumRequest
export const deleteForumRequest = createAsyncThunk(
  'forums/deleteForumRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await forumAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allForums: [],
  singleForum: {},
  errorInForum: ''
};

export const forumSlice = createSlice({
  name: 'forums',
  initialState,
  reducers: {
    setAllForums: (state, action) => {
      state.allForums = action.payload;
    },
    setSingleForum: (state, action) => {
      state.singleForum = action.payload;
    },
    clearHasError: (state) => {
      state.errorInForum = '';
    }
  },

  extraReducers: (builder) => {
    builder.addCase(addNewForumRequest.rejected, (state, action) => {
      state.errorInForum = action.payload;
    });
    builder.addCase(getAllForumsRequest.rejected, (state, action) => {
      state.errorInForum = action.payload;
    });
    builder.addCase(getSingleForumRequest.rejected, (state, action) => {
      state.errorInForum = action.payload;
    });
    builder.addCase(deleteForumRequest.rejected, (state, action) => {
      state.errorInForum = action.payload;
    });
  }
});
export const { setAllForums, setSingleForum, clearHasError } = forumSlice.actions;

export default forumSlice.reducer;
