import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    '.ql-toolbar': {
      position: 'sticky',
      top: '1px',
      zIndex: '10000',
      background: '#fff'
    },
    '.ck.ck-content': {
      height: '300px'
    },
    'ul li.ck-list-bogus-paragraph': {
      listStyleType: 'disc',
      paddingLeft: '10px',
      color: '#000'
    },
    '.ql-tooltip.ql-editing.ql-flip': {
      left: 10
    },
    '.ql-snow .ql-picker.ql-font [data-value="arial"].ql-picker-item::before': {
      content: '"Arial"'
    },
    '.ql-snow .ql-picker.ql-font [data-value="arial"].ql-picker-label::before': {
      content: '"Arial"'
    },
    '.ql-snow .ql-picker.ql-font [data-value="calibri"].ql-picker-item::before': {
      content: '"Calibri"'
    },
    '.ql-snow .ql-picker.ql-font [data-value="calibri"].ql-picker-label::before': {
      content: '"Calibri"'
    }
  }
}))(() => null);

export default GlobalStyles;
