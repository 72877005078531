import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { writerAPI } from '../api/writerAPI';
import { setMessage } from './appSlice';

// async thunk request to Register New writer
export const addNewWriterRequest = // eslint-disable-next-line
  createAsyncThunk('writers/addNewWriterRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await writerAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All writers
export const getAllWritersRequest = createAsyncThunk(
  'writers/getAllWritersRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await writerAPI.all();
      dispatch(setAllWriters(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single writer
export const getSingleWriterRequest = createAsyncThunk(
  'writers/getSingleWriterRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await writerAPI.single(dt);
      dispatch(setSingleWriter(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to updateWriterInfoRequest
export const updateWriterInfoRequest = createAsyncThunk(
  'writers/updateWriterInfoRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await writerAPI.update(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteWriterRequest
export const deleteWriterRequest = createAsyncThunk(
  'writers/deleteWriterRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await writerAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allWriters: [],
  singleWriter: {},
  errorInWriter: ''
};

export const writerSlice = createSlice({
  name: 'writers',
  initialState,
  reducers: {
    setAllWriters: (state, action) => {
      state.allWriters = action.payload;
    },
    setSingleWriter: (state, action) => {
      state.singleWriter = action.payload;
    },

    clearHasError: (state) => {
      state.errorInWriter = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addNewWriterRequest.rejected, (state, action) => {
      state.errorInWriter = action.payload;
    });
    builder.addCase(getAllWritersRequest.rejected, (state, action) => {
      state.errorInWriter = action.payload;
    });
    builder.addCase(getSingleWriterRequest.rejected, (state, action) => {
      state.errorInWriter = action.payload;
    });
    builder.addCase(updateWriterInfoRequest.rejected, (state, action) => {
      state.errorInWriter = action.payload;
    });
    builder.addCase(deleteWriterRequest.rejected, (state, action) => {
      state.errorInWriter = action.payload;
    });
  }
});
export const { setAllWriters, setSingleWriter, clearHasError } = writerSlice.actions;

export default writerSlice.reducer;
