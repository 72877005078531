// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/main')
  },

  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    all: path(ROOTS_DASHBOARD, '/blogs/all-blogs'),
    add: path(ROOTS_DASHBOARD, '/blogs/add-new-blog')
  },

  pages: {
    root: path(ROOTS_DASHBOARD, '/pages'),
    all: path(ROOTS_DASHBOARD, '/pages/all-pages'),
    add: path(ROOTS_DASHBOARD, '/pages/add-new-page')
  },

  forums: {
    root: path(ROOTS_DASHBOARD, '/forums'),
    all: path(ROOTS_DASHBOARD, '/forums/all-forums')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    all: path(ROOTS_DASHBOARD, '/category/all-blogs-category')
  },
  tags: {
    root: path(ROOTS_DASHBOARD, '/tags'),
    all: path(ROOTS_DASHBOARD, '/tags/all-tags')
  },
  writers: {
    root: path(ROOTS_DASHBOARD, '/writers'),
    all: path(ROOTS_DASHBOARD, '/writers/all-writers')
  }
};
