import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/appSlice';
import userReducer from '../features/userSlice';
import blogReducer from '../features/blogSlice';
import forumReducer from '../features/forumSlice';
import categoryReducer from '../features/categorySlice';
import tagReducer from '../features/tagSlice';
import leadReducer from '../features/leadSlice';
import writerReducer from '../features/writerSlice';
import pageReducer from '../features/pageSlice';
import notificationReducer from '../features/notificationSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    blogs: blogReducer,
    tags: tagReducer,
    categories: categoryReducer,
    leads: leadReducer,
    notifications: notificationReducer,
    forums: forumReducer,
    writers: writerReducer,
    pages: pageReducer
  }
});
