import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { leadAPI } from '../api/leadAPI';
import { setMessage } from './appSlice';

// async thunk request to Register new Lead
export const addLeadRequest = // eslint-disable-next-line
  createAsyncThunk('leads/addLeadRequest', async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const reg = await leadAPI.add(dataToSend);
      dispatch(setMessage(reg.message));
    } catch (error) {
      if (error.response) {
        console.log(error.response, 'error response: ');
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  });

// async thunk request to get All leads
export const getAllLeadsRequest = createAsyncThunk(
  'leads/getAllLeadsRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await leadAPI.all();
      dispatch(setAllLeads(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to get Single lead
export const getSingleLeadRequest = createAsyncThunk(
  'leads/getSingleLeadRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await leadAPI.single(dt);
      dispatch(setSingleLead(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

// async thunk request to deleteLeadRequest
export const deleteLeadRequest = createAsyncThunk(
  'leads/deleteLeadRequest',
  // eslint-disable-next-line
  async (dt, { dispatch, rejectWithValue }) => {
    try {
      const data = await leadAPI.delete(dt);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  allLeads: [],
  singleLead: {},
  errorInLead: ''
};

export const leadSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setAllLeads: (state, action) => {
      state.allLeads = action.payload;
    },
    setSingleLead: (state, action) => {
      state.singleLead = action.payload;
    },
    clearHasError: (state) => {
      state.errorInLead = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addLeadRequest.rejected, (state, action) => {
      state.errorInLead = action.payload;
    });
    builder.addCase(getAllLeadsRequest.rejected, (state, action) => {
      state.errorInLead = action.payload;
    });
    builder.addCase(getSingleLeadRequest.rejected, (state, action) => {
      state.errorInLead = action.payload;
    });
    builder.addCase(deleteLeadRequest.rejected, (state, action) => {
      state.errorInLead = action.payload;
    });
  }
});
export const { setAllLeads, setSingleLead, clearHasError } = leadSlice.actions;

export default leadSlice.reducer;
